import React, { useState, Suspense, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import '../i18n/i18n';
import {useTranslation} from 'react-i18next';
import axios from "axios";

function ContactForm() {
    const {t} = useTranslation();
    const [nameCompany, setNameCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [service, setService] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    useEffect(() => {
        const loadRecaptcha = () => {
            setRecaptchaLoaded(true);
        };

        // Add event listeners or conditions here where you might expect the user is ready to submit
        const formElement = document.getElementById('contact-form');
        formElement.addEventListener('mouseenter', loadRecaptcha, { once: true });

        return () => {
            formElement.removeEventListener('mouseenter', loadRecaptcha);
        };
    }, []);

    const validateForm = () => {
        const nameCompanyRegex = /^[a-zA-Z\s,.']+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+?[0-9]+$/;

        if (!nameCompanyRegex.test(nameCompany)) {
            setError(t('contact-form-name-company-validation'));
            return false;
        }
        if (!emailRegex.test(email)) {
            setError(t('contact-form-email-validation'));
            return false;
        }
        if (!phoneRegex.test(phoneNumber)) {
            setError(t('contact-form-phone-number-validation'));
            return false;
        }
        if (message.length > 0 && /<|>/.test(message)) {
            setError(t('contact-form-message-validation'));
            return false;
        }
        if (!captchaValue) {
            setError(t('contact-form-captcha-validation'));
            return false;
        }

        setError('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            const formData = {nameCompany, email, phoneNumber, message, service};
            try {
                const response = await axios.post('https://h1t8h2pqwe.execute-api.us-east-1.amazonaws.com', formData);
                setSuccessMessage(t('contact-form-success'));
                setNameCompany('');
                setEmail('');
                setPhoneNumber('');
                setMessage('');
                setService('');
                setCaptchaValue(null);
            } catch (error) {
                console.log(error);
                setError(t('contact-form-submit-error'));
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <div className="contact-form">
            {error && <div className="form-error">{error}</div>}
            {successMessage && <div className="form-success">{successMessage}</div>}
            <form id="contact-form" onSubmit={handleSubmit}>
                <input type="text" value={nameCompany} onChange={(e) => setNameCompany(e.target.value)}
                       placeholder={t('contact-form-name')} required/>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                       placeholder={t('contact-form-email')} required/>
                <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                       placeholder={t('contact-form-phone-number')} required/>
                <select value={service} onChange={(e) => setService(e.target.value)} required>
                    <option value="">{t('contact-form-services')}</option>
                    <option value="website development">{t('contact-form-service-website-development')}</option>
                    <option value="web maintenance service">{t('contact-form-service-website-maintenance')}</option>
                    <option value="cto service">{t('contact-form-service-cto-service')}</option>
                    <option value="web application development">{t('contact-form-service-web-application')}</option>
                    <option value="infrastructure consultation">{t('contact-form-service-infra-consulting')}</option>
                    <option value="general">{t('contact-form-service-general')}</option>
                </select>
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" required/>
                {recaptchaLoaded && (
                    <Suspense fallback={<div>Loading Captcha...</div>}>
                        <ReCAPTCHA
                            sitekey="6LfZLMspAAAAAG_o7WsdrxDkD9yp7_yXk43EqhEQ"
                            onChange={setCaptchaValue}
                        />
                    </Suspense>
                )}
                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? t('contact-form-submit-button-sending') : t('contact-form-submit-button')}
                </button>
            </form>
        </div>
    );
}

export default ContactForm;
