import React from 'react';
import '../scss/Gallery.scss'; // Import the CSS for styling

const images = [
    require('../imgs/tech/img.png'),
    require('../imgs/tech/img_1.png'),
    require('../imgs/tech/img_2.png'),
    require('../imgs/tech/img_3.png'),
    require('../imgs/tech/img_4.png'),
    require('../imgs/tech/img_5.png'),
    require('../imgs/tech/img_6.png'),
    require('../imgs/tech/img_9.png'),
    require('../imgs/tech/img_10.png'),
];

const ImageGallery = () => {
    return (
        <div className="gallery">
            <div className="image-container row align-center">
                {images.map((image, index) => (
                    <div key={index} className="col-1-2 col-sm-1-2 col-md-1-3">
                        <img src={image} alt={`Gallery ${index}`}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageGallery;
