import React, { useState, useEffect } from 'react';

function CodeAnimation() {
    const [text, setText] = useState('');
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);

    const codeSnippets = [
        'install Tallpro-Services\n',
        '// Consulting about technical solutions\n',
        'Install e-commerce-solution\n',
        '/* Developing Web App & E-commerce */\n',
        '// Securing website from DDoS attacks and data leaks\n',
        '/* SEO Setup: Ensure Google Search Console integration */\n',
        '/* QA Phase: Run automated and manual tests */\n',
        'console.warn("Scanning for data breaches in dark web...");\n',
        '/* AI Integration: Implement machine learning */\n',
        '--build-platform\n',
        '/* Installing Data tracking */ \n',
        '/* Installing payment services */ \n',
        '//building dashboards for the business \n',
        '__Platform successfully launched__\n'
    ];

    useEffect(() => {
        if (index === codeSnippets.length) {
            setTimeout(() => {
                setText('');
                setIndex(0);
                setSubIndex(0);
            }, 3000); // Delay before restart
            return;
        }

        if (subIndex === codeSnippets[index].length) {
            setTimeout(() => {
                setIndex(index + 1);
                setSubIndex(0);
            }, 500); // Delay before next line
            return;
        }

        const timeout = setTimeout(() => {
            setText((prev) => prev + codeSnippets[index][subIndex]);
            setSubIndex(subIndex + 1);
        }, 50); // Reduced typing speed to 100 milliseconds

        return () => clearTimeout(timeout);
    }, [subIndex, index, codeSnippets]);

    return (
        <div className="code-animation-container">
            <pre>{text}<span className="cursor" /></pre>
        </div>
    );
}

export default CodeAnimation;
