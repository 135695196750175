import React from 'react';
import {Helmet} from 'react-helmet';

const Head = ({title, description, keywords, image}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta charSet="UTF-8"/>
            <link rel="canonical" href={window.location.href}/>
            <meta name="robots" content="index, follow"/>

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            {/* Replace URL_to_image with your image link */}

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content={window.location.href}/>
            <meta property="twitter:title" content={title}/>
            <meta property="twitter:description" content={description}/>
            <meta property="twitter:image" content={image}/>
            {/* Replace URL_to_image with your image link */}

            {/* Additional tags for security and performance */}
            <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="strict-transport-security" content="max-age=63072000; includeSubDomains"/>
            <meta name="Content-Security-Policy" content="default-src 'self'"/>
            <meta name="x-content-type-options" content="nosniff"/>
            <meta name="x-frame-options" content="SAMEORIGIN"/>
            <meta name="referrer-policy" content="no-referrer-when-downgrade"/>
        </Helmet>
    );
};

export default Head;
