import React, {useState, useEffect} from 'react';
import logo from './imgs/tp_logo.svg'; // Assuming you have a logo.svg in your src folder
import './scss/Homepage.scss';
import './components/Head.js';
import Head from "./components/Head";
import CodeAnimation from "./components/CodeAnimation";
import ImageGallery from "./components/ImageGallery";
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import ContactForm from "./components/ContactForm";

function Homepage() {
    const { t } = useTranslation();
    const contactEmailName = "info";
    const contactEmailDomain = t('email-domain');
    const title = t('seo-title');
    const description = t('seo-description');
    const keywords = t('seo-keywords');

    const [emailAddress, setEmailAddress] = useState('');

    // Assemble the email address once component mounts
    useEffect(() => {
        setEmailAddress(`${contactEmailName}@${contactEmailDomain}`);
    }, []);

    return (
        <div className="container">
            <Head title={title} description={description} keywords={keywords} image={logo}/>
            <header>
                <img src={logo} alt="Software & Web Development / Technical Consulting"
                     title="Software & Web Development / Technical Consulting" className="logo"/>
            </header>
            <main>
                <section className="py-3 px-2 my-6 bg-primary text-secondary section-cut-corners">
                    <h1 className="p-0 m-0">{t('homepage-h1')}</h1>
                </section>
                <section className="text-left">
                    <div className="row">
                        <div className="col-sm-1-1 col-md-1-2 col-lg-1-2">
                            <h2 className="m-0 p-0">{t('homepage-about')}</h2>
                            <p>{t('about-description')}</p>
                            <p>{t('about-tailoring')}</p>
                            <p>{t('about-ai')}</p>
                        </div>
                        <div className="col-sm-1-1 col-md-1-2 col-lg-1-2">
                            <CodeAnimation/>
                        </div>
                    </div>
                </section>
                <section className="my-6">
                    <div className="grid">
                        <div className="col-1-of-1">
                            <h2 className="p-0 m-0 py-1 text-left position-relative">{t('technologies-title')}</h2>
                            <div className="my-4">
                                <ImageGallery />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="my-0">
                    <div className="grid">
                        <div className="col-1-of-1">
                            <h2 className="p-0 m-0 py-1 text-left">{t('contacts-title')}</h2>
                            <div className="my-4">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="footer">
                <p className="footer-content">&copy; {new Date().getFullYear()} {t('footer-tallpro')}</p>
                <p className="footer-content">{t('footer-emailus')} <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
            </footer>
        </div>
    );
}

export default Homepage;
